.top1 {
    background: url("../../public/assets/photo15.png"), url('../../public/assets/photo18.png');
    background-repeat: no-repeat;
    background-size: 270px;
    background-position: left 0 top 0, right 0 top 0;
    margin: 40px -50px;
    padding: 40px;
}

.contant {
    border: 2px solid #fff;
    padding: 40px 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    /* justify-content: center; */
    position: relative;
}

.contant>p {
    color: #fff;
    font-size: 22px;
    font-family: 'Poppins', sans-serif;
    margin-top: 5rem;
    font-weight: 200;
}

.box {
    background: #fff;
    width: fit-content;
    padding: 10px 15px;
    font-weight: 700;
    font-family: "Poppins", sans-serif;
    font-size: 24px;
    margin: auto;
    position: absolute;
    top: -40px;
}

.top1_contents {
    justify-content: center;
    align-items: center;
    display: flex;
}

.logo {
    width: 250px;
    margin-top: 2rem;
    margin-bottom: 7rem;
}

.button_container {
    display: flex;
    grid-gap: 15px;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
}

.button {
    width: 35px;
    height: 23px;
    font-family: "Poppins", sans-serif;
    font-weight: 500;
    color: #000;
    background: rgb(236 240 243);
    border-radius: 3px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.link {
    text-decoration: none;
    background: #000;
    color: #fff;
    /* margin: 5px; */
    width: 150px;
    margin: 10px;
    text-align: center;
    padding: 8px;
    border-radius: 4px;
    font-family: "Poppins", sans-serif;
}


@media (max-width: 1199px) {
    .header_content h1 {
        font-size: 24px;
    }

    .content_left h1 {
        font-size: 20px;
    }

    .logo {
        margin-top: 6rem;
    }
}

@media (max-width: 1060px) {}

@media (max-width: 991px) {
    .mid_section {
        margin: 3rem 1rem;
    }

    .top1 {
        padding: 0 20px;
        margin: 40px 0;
    }

    .logo {
        margin-top: 3rem;
        margin-bottom: 3rem;
    }

    .contant {
        margin: 3rem 1rem;
    }
}

@media (max-width: 767px) {
    .top1 {
        background-position: left -34px top 0, right -36px top 0, left 50% top 70%;
        margin: 40px 0;
        background-size: 210px auto, 221px auto, 355px auto;
    }

    .contant {
        margin: 2rem 1rem;
    }

    .contant>p {
        margin-top: 3rem;
        font-size: 18px;
    }
}

@media (max-width: 575px) {
    .top1 {
        background-position: left -34px top 0, right -36px top 0, left 50% top 50%;
        margin: 40px 0;
        background-size: 150px auto, 150px auto, 310px auto;
    }

    .logo {
        width: 170px;
    }

    .contant {
        margin: 0rem;
        padding: 28px 15px;
    }

    .contant>p {
        margin-top: 1rem;
        padding: 28px 10px;
    }

    .box {
        font-size: 20px;
        padding: 8px 13px;
        top: -33px;
    }

    .button_container {
        grid-gap: 10px;
    }
}