.top1 {
  background:url('../../public/assets/photo12.png'),url('../../public/assets/photo13.png'),url('../../public/assets/photo14.png');
  background-repeat: no-repeat;
  background-size: 270px auto, 270px auto, 430px auto;
  background-position: left 0 top 0, right 0 top 0, left 50% top 80%;
  margin:40px -50px;
  padding: 0 40px;
}

.contant>p {
  color: #fff;
  font-size: 22px;
  font-family: 'Poppins', sans-serif;
 
  font-weight: 200;
}

.top1_contents {
  justify-content: center;
  align-items: center;
  display: flex;
}

.logo {
  width: 250px;
  margin-top: 4rem;
}

.hn{
  display:flex;
  text-align: center;
  justify-content: center;
  margin-top: 2rem;
  font-family: 'Poppins',sans-serif;
}
h2{
  color: rgb(249 195 89);
  margin-top: 2rem;
  font-family: 'Poppins',sans-serif;
}

h3{
  color: rgb(249 195 89);
  font-family: "Poppins",sans-serif;
  margin-top: 15px;

}
.res>span{
  color: #fff;
  font-weight: 800;
}
.res{
  color: rgb(249 195 89);
  font-family: "Poppins",sans-serif;
  font-size: 20px;
  margin-top: 12px;
}
nav>p{
  color: #fff;
  font-size: 20px;
  font-family: "Poppins";
  font-weight: 300;
  
  
}

.btn{
  background-color: rgb(172, 18, 70);
  font-size: small;
  font-family: sans-serif;
  border: none;
  border-radius: 4px;
  margin-left: 2rem;
  margin-top: 15px;
  width: 10rem;
  height: 30px;
  
  
  
}

.btn a{
  color: #fff;
  font-weight: 500;
  font-size: 18px;
  font-family:'Poppins', sans-serif;
}


@media (max-width: 1199px) {
  .header_content h1 {
    font-size: 24px;
  }

  .content_left h1 {
    font-size: 20px;
  }

  .logo {
    margin-top: 6rem;
  }
}

@media (max-width: 1060px) {}

@media (max-width: 991px) {
  .mid_section {
    margin: 3rem 1rem;
  }

  .top1 {
    padding: 0 20px;
    margin: 40px 0;
  }

  .logo {
    margin-top: 3rem;
    margin-bottom: 3rem;
  }

  .contant {
    margin: 3rem 1rem;
  }
}

@media (max-width: 767px) {
  .top1 {
    background-position: left -34px top 0, right -36px top 0, left 50% top 70%;
    margin: 40px 0;
    background-size: 210px auto, 221px auto, 355px auto;
  }

  .contant {
    margin: 2rem 1rem;
  }

  .contant>p {
    margin-top: 3rem;
    font-size: 18px;
  }
}

@media (max-width: 575px) {
  .top1 {
    background-position: left -34px top 0, right -36px top 0, left 50% top 50%;
    margin: 40px 0;
    background-size: 150px auto, 145px auto, 310px auto;
  }

  .logo {
    width: 170px;
  }

  .contant {
    margin: 1rem;
  }

  .contant>p {
    margin-top: 1rem;
  }
}