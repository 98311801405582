h1 {
  color: rgb(249 195 89);
  font-family: 'Poppins', sans-serif
}

.hding {
  justify-content: center;
  text-align: center;
  display: flex;
  font-size: 35px;
  margin-top: -20px;
}

.hding_cnt {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.hding_cnt img {
  width: 450px;
  margin: auto;
}

.navs {
  color: #fff;
  font-size: 22px;
  font-family: 'Poppins';
  margin: 10px;

}

.hs {
  color: rgb(249, 195, 89);
  font-weight: 800;
  padding-bottom: 20px;
  font-size: 25px;
  font-family: "Poppins", sans-serif;
}

.header {
  background: url("../../public/assets/photo1.png"), url("../../public/assets/photo2.png"), url("../../public/assets/photo3.png");
  background-repeat: no-repeat;
  background-position: top 25px left 0px, top 25px right 0px, top 40% left 50%;
  margin: -50px;
  background-size: 270px auto, 270px auto, 350px auto;
  padding: 40px;
}

span {
  font-family: 'Poppins', sans-serif;
  font-weight: 700;
  font-size: 18px;
}

/* hr {
  border: 3px solid rgb(155 31 83);
  border-radius: 4px;
} */

.img {
  width: 170px;
  height: 90px;
  border-radius: 150px;
}

.header_content {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin: 0 auto;
  padding: 1rem 0;
  padding-top: 4rem;
}

.logo1 {
  position: relative;
  /* top: 35px;
  justify-content: center;
  align-items: center;
  display: flex;
  width: 250px; */
  width: 250px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  margin-bottom: 60px;
  padding-top: 1rem;
}

.text {
  color: #fff;
  font-family: "Montserrat", sans-serif;
  line-height: 30px;
  font-size: 18px;
}

.text span {
  color: rgb(249 195 89);
  font-weight: 500;
}

.main_section {
  display: flex;
  flex-direction: row;
  gap: 2rem;
  padding: 2rem 0;
  text-align: start;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 auto;
  padding: 1rem;
}


.content_left {
  width: 60%;
  animation: slideFromLeft 1s ease forwards;
  opacity: 0;
  margin-top: 20px;
}

.content_left> {
  font-size: 30px;
}

@keyframes slideFromLeft {
  0% {
    opacity: 0;
    transform: translateX(-100%);
  }

  100% {
    opacity: 1;
    transform: translateX(0%);
  }
}

.buttun_group {
  display: flex;
  gap: 2rem;
  padding: 1rem;
  margin-top: 1rem;
  align-items: center;
  justify-content: center;
}

.PROOF,
.NFT {
  border: none;
  border-radius: 1.5rem;
  background-color: #fff;
  color: black;
  font-size: 14px;
  font-weight: 800;
  width: fit-content;
  padding: .4rem 30px;
  cursor: pointer;
  text-align: center;
  font-family: "Poppins", sans-serif;
}

.content_right {
  animation: slideFromRight 1s ease forwards;
  width: 40%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-left: 25px;

}

@keyframes slideFromRight {
  0% {
    opacity: 0;
    transform: translateX(100%);
  }

  100% {
    opacity: 1;
    transform: translateX(0%);
  }
}




.image_container {
  width: 32.5rem;
  margin-top: 7.5rem;
}

.image_container img {
  width: 100%;
}

.button_group {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-top: 60px;
}

.button_group>button {
  border-radius: 20px;
  text-align: center;
  font-weight: 600;
}

@media (max-width: 1199px) {
  .header_content h1 {
    font-size: 24px;
  }

  .content_left h1 {
    font-size: 20px;
  }
}

@media (max-width: 1060px) {}

@media (max-width: 991px) {
  .main_section {
    flex-direction: column;
    justify-content: flex-start;
  }

  .hding {
    font-size: 26px;
  }

  .navs {
    font-size: 18px;
  }

  .hs {
    font-size: 22px;
  }

  .text {
    font-size: 16px;
  }

  .header_content {
    flex-direction: column-reverse;
  }

  .logo1 {
    top: 0;
    align-self: flex-end;
  }

  .content_left {
    width: 100%;
  }

  .content_right {
    width: 100%;
  }

  .image_container {
    width: 50%;
    margin-top: 2rem;
  }

  .button_group {
    margin-top: 15px;
  }

  .content_left {
    background-position: left 200px bottom 90px;
  }

  .header {
    background-position: top -25px left -25px, top -25px right -25px, top 26% left 50%;
    margin: 0;
    background-size: 215px auto, 215px auto, 300px auto;
    padding: 15px;
  }
}

@media (max-width: 767px) {
  .header_content h1 {
    font-size: 21px;
  }

  .image_container {
    width: 70%;
  }

  .PROOF,
  .NFT {
    border-radius: 1.5rem;
    font-size: 12px;
    font-weight: 600;
    padding: .2rem 20px;
  }

  .header {
    background-position: top -25px left -25px, top -25px right -25px, top 26% left 50%;
    margin: 0;
    background-size: 215px auto, 215px auto, 300px auto;
    padding: 15px;
  }

  .header_content {
    padding-top: 2rem;
  }

  .logo1 {
    margin-bottom: 40px;
  }
}

@media (max-width: 575px) {

  .image_container {
    width: 75%;
  }

  .hding_cnt img {
    width: 300px;
    margin: auto;
  }

  .header {
    background-position: top -25px left -25px, top -25px right -25px, top 26% left 50%;
    margin: 0;
    background-size: 215px auto, 215px auto, 300px auto;
    padding: 15px;
  }
}

@media (max-width: 475px) {
  .header {
    background-position: top -25px left -25px, top -25px right -25px, top 26% left 50%;
    margin: 0;
    background-size: 150px auto, 150px auto, 300px auto;
  }

  .hding {
    font-size: 22px;
  }

  .navs {
    font-size: 16px;
  }

  .hs {
    font-size: 19px;
  }

  .text {
    font-size: 16px;
  }

  .text span {
    font-size: 18px;
  }

  .logo1 {
    width: 170px;
  }
}