@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  background-color: #000;
  min-height: 100vh;
  max-width: 1400px;
  margin: auto;
  overflow-x: hidden;
  padding: 0;
  align-items: center;
}

a {
  text-decoration: none;
}

/* .section-img {
  background-image: url('./pngImages/[removal.ai]_ff1f34c1-b16e-4f22-b94c-01edbaa62c8e_Introducing\ the\ Proof-of-Gods\ \(17\).png');
  height: 400px;
  width: 500px;
  border: none;
  background-repeat: no-repeat;
  background-size: cover;
} */

.container {
  width: 100%;
  padding: 0 40px;


}

@media (max-width: 991px) {
  .container {
    padding: 0 0px;
  }
}

@media (max-width: 575px) {
  .wallet_address {
    font-size: 10px;
  }
}