.opt {
  margin-top: 2rem;
  padding: auto;
}

.spn {
  color: rgb(249 195 89);
  font-family: 'Poppins', sans-serif;
  font-weight: 300;
  max-width: 70%;
  font-size: 18px;

}
.pan{
  color: rgb(249 195 89);
  font-family: 'Poppins', sans-serif;
  font-weight: 300;
  max-width: 70%;
  font-size: 16px;
}

.top_section1> {
  font-family: sans-serif;
  font-weight: 800;
  margin-left: 9px;
  color: whitesmoke;
  margin: 8px;
  margin-top: 1rem;

}

.top {
  margin-top: 6rem;
  background: url("../../public/assets/photo4.png"), url("../../public/assets/photo5.png"), url("../../public/assets/photo6.png");
  background-repeat: no-repeat;
  background-position: top 0 left 0, top 0 right 0, top 55% left 50%;
  margin: -50px;
  padding: 40px;
  margin-top: 65px;
  background-size: 270px auto, 270px auto, 440px auto;
}

.hd {
  color: white;
  margin: 3px;
  font-size: 30px;

}

.logo2 {
  position: relative;
  width: 250px;
  justify-content: center;
  align-items: center;
  display: flex;
  margin: 0 auto;
  margin-bottom: 55px;
  padding-top: -4rem;
  justify-content: space-between;

}

.opt>p {
  color: white;
  margin-left: 1px;
  font-size: 20px;
  font-family: 'Poppins', sans-serif;



}

.contain {

  display: flex;
  flex-direction: row;
  gap: 2rem;
  padding: 2rem 0;
  text-align: start;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 auto;
  padding: 1rem;
}

.top_content {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin: 0 auto;
  padding: 2rem 0;
}




.top_section1 {
  padding: 2rem 0;
  text-align: start;
  margin: 0 auto;

}

.left_side {
  color: white;
}

.left_side>p {
  max-width: 70%;
  font-size: 20px;
  font-family: 'Poppins', sans-serif;
  font-weight: 300;
}

.Right_side {
  max-width: 30%;
  margin-right: 2rem;
}

.top_section h3 {
  color: #fff;
  font-family: 'Poppins', sans-serif;
  font-weight: 600;
  line-height: 30px;
  font-weight: normal;
}




@media (max-width: 1199px) {
  .content_left h1 {
    font-size: 20px;
  }

  .top {
    margin-top: 3rem;
  }
}

@media (max-width: 1060px) {}

@media (max-width: 991px) {
  .top_content {
    flex-direction: column-reverse;
  }

  .top {
    margin-top: 1rem;
    margin: 0;
  }

  .logo {
    top: 0;
    align-self: flex-end;
  }

  .btn_group {
    margin-top: 0px;
  }

  .contain {
    flex-direction: column;
  }

  .Right_side {
    width: 90%;
    max-width: 90%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .hd {
    font-size: 24px;
  }

  .opt p {
    font-size: 18px;
  }

  .left_side > p {
    max-width: 100%;
    width: 100%;
    font-size: 18px;
  }
}

@media (max-width: 767px) {
  .header_content h1 {
    font-size: 21px;
  }

  .image_container {
    width: 70%;
  }

  .top {
    padding: 20px;
    margin-top: 2rem;
  }

  .top_section h3 {
    font-size: 17px;
  }

  .PROOF,
  .NFT {
    border-radius: 1.5rem;
    font-size: 12px;
    font-weight: 600;
    padding: .2rem 20px;
  }

  .top {
    background-position: top 0 left -8px, top 0 right -15px, top 50% left 50%;
    background-size: 198px auto, 204px auto, 325px auto;
  }
  .Right_side {
    width: 70%;
    max-width: 70%;
  }

  .Right_side img {
    width: 100%;
  }
}

@media (max-width: 575px) {
  .top {
    background-position: top 0 left -8px, top 0 right -15px, top 55% left 50%;
    background-size: 143px auto, 150px auto, 315px auto;
  }

  .logo2 {
    width: 170px;
    margin-bottom: 0;
  }

  .opt p {
    font-size: 16px;
  }

  .top span {
    font-size: 16px;
  }

  .image_container {
    width: 75%;
  }

  .hd {
    font-size: 20px;
  }

  .Right_side {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0;
  }

  .left_side > p {
    font-size: 16px;
  }
}