.top1 {
  background: url("../../public/assets/photo9.png"), url('../../public/assets/photo10.png'), url('../../public/assets/photo11.png');
  background-repeat: no-repeat;
  background-size: 270px;
  background-position: left 0 top 0, right 0 top 0, left 50% top 80%;
  margin:40px -50px;
  padding: 0 40px;
}

.contant>p {
  color: #fff;
  font-size: 22px;
  font-family: 'Poppins', sans-serif;
  margin-top: 5rem;
  font-weight: 300;
}

.top1_contents {
  justify-content: center;
  align-items: center;
  display: flex;
}

.logo {
  width: 250px;
  margin-top: 9rem;
}



@media (max-width: 1199px) {
  .header_content h1 {
    font-size: 24px;
  }

  .content_left h1 {
    font-size: 20px;
  }

  .logo {
    margin-top: 6rem;
  }
}

@media (max-width: 1060px) {}

@media (max-width: 991px) {
  .mid_section {
    margin: 3rem 1rem;
  }

  .top1 {
    padding: 0 20px;
    margin: 40px 0;
  }

  .logo {
    margin-top: 3rem;
    margin-bottom: 3rem;
  }

  .contant {
    margin: 3rem 1rem;
  }
}

@media (max-width: 767px) {
  .top1 {
    background-position: left -34px top 0, right -36px top 0, left 50% top 70%;
    margin: 40px 0;
    background-size: 210px auto, 221px auto, 355px auto;
  }

  .contant {
    margin: 2rem 1rem;
  }

  .contant>p {
    margin-top: 3rem;
    font-size: 18px;
  }
}

@media (max-width: 575px) {
  .top1 {
    background-position: left -34px top 0, right -36px top 0, left 50% top 50%;
    margin: 40px 0;
    background-size: 150px auto, 150px auto, 310px auto;
  }

  .logo {
    width: 170px;
  }

  .contant {
    margin: 1rem;
  }

  .contant>p {
    margin-top: 1rem;
  }
}